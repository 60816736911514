/*
 * Want to change your theme colors?
 * Try uncommenting the color overrides below
 * to go from default purple to a blue theme
 */

import "../custom.css"

const theme = {
    colors: {
        text: "#001",
        background: "#fee",
        primary: "#a53",
    },
    fonts: {
        body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, sans-serif',
        heading: 'Georgia, serif',
    },
    fontSizes: [14, 16, 18, 24, 32, 48, 64],
}

export default theme
